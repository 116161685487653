import React from 'react'

const Service = () => {
  return (
    <div className='w-full h-auto justify-center items-center bg-white'>
        
        <div className='w-full mx-auto md:px-16 md:max-w-[1440px]'>
            
            <div className='grid md:grid-cols-3 gap-8 py-8'>
                <div className='bg-[#f4f6fb] min-h-[300px] p-8'>
                    <h1 className='md:text-xl lg:text-2xl text-2xl font-bold'>Studiamo la tua azienda e i tuoi progetti.</h1>
                    <p className='pt-4 mt-4 border-t border-slate-400 md:text-base text-lg'>Se la tua azienda ha progetti di investimento, con buona probabilità possono essere sostenuti con incentivi pubblici.</p>
                </div>
                
                <div className='bg-[#f4f6fb] min-h-[300px] p-8'>
                    <h1 className='md:text-xl lg:text-2xl text-2xl font-bold'>Troviamo ogni agevolazione che puoi ricevere.</h1>
                    <p className='pt-4 mt-4 border-t border-slate-400 md:text-base text-lg'>Contributi a fondo perduto, crediti d'imposta, finanziamenti agevolati... studiamo attentamente ogni opportunità agevolativa.</p>
                </div>
                
                <div className='bg-[#f4f6fb] min-h-[300px] p-8'>
                    <h1 className='md:text-xl lg:text-2xl text-2xl font-bold'>Inizia a risparmiare ora, e nel tempo.</h1>
                    <p className='pt-4 mt-4 border-t border-slate-400 md:text-base text-lg'>Gestiamo ogni bando e pratica di agevolazione al posto tuo, dalla presentazione della domanda all'erogazione dell'incentivo.</p>
                </div>
            </div>
            

        </div>
    
    </div>
  )
}

export default Service