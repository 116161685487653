import React from 'react'
import {AiOutlineInstagram} from 'react-icons/ai'
import {BiLogoFacebook} from 'react-icons/bi'

const Footer = () => {
  return (
    <div className='bg-gray-500 w-full min-h-[20rem] text-white'>
        <div className='flex-1 mx-auto md:px-16 px-8 max-w-[1440px]'>
            
            <div className='grid gap-4 md:grid-cols-3 grid-flow-row w-full item-center pt-4'>
              
              <div className='py-4 md:border-0 border-b border-white/50'>
                <h1>Richiedi una consulenza</h1>
              </div>
              
              <div className='py-4 md:border-0 border-b border-white/50'>
                <h1 className='text-2xl font-bold pb-4'>Dove siamo</h1>
                <p><b>Italy - Abruzzo</b><br/>
                  Miglianico (CH)<br/>
                  Via Roma 90<br/>
                  66010 </p>  
              </div>
              
              <div className='py-4'>
                {/* <h1 className='text-2xl font-bold pb-4'>Contattaci</h1> */}
                <p>Telefono<br/>
                <b>(+39) 0871 447513</b><br/>
                <b>(+39) 346 3342392</b><br/><br/>
                E-mail<br/>
                <b>info@studioguarnieri.ue</b></p><br/>
                <div className='flex gap-4'>
                  <BiLogoFacebook size={24}/>
                  <AiOutlineInstagram size={24}/>
                </div>
              </div>

            </div>
            <div className='text-white/50 text-xs pt-8 pb-16'>IdleFoxSutdio &copy;2024 . All rights reserved.</div>
              
        </div>
    </div>
  )
}

export default Footer