import React from 'react'
import hero from "../imgs/hero_w.jpg"
import home1 from "../imgs/home_1.jpg"
import {AiOutlinePhone} from 'react-icons/ai'

const Home = () => {
  return (
        <div className='w-full h-auto justify-center items-center'>
           
            <div className='w-full mx-auto md:px-16 md:max-w-[1440px]' >

              <div className='md:h-[40rem] h-[25rem]' style={{backgroundImage: `url(${hero})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPositionX: "center"
                }}>

                  <h1 className='font-bold lg:text-5xl md:text-4xl text-2xl px-6 md:pt-16 pt-4'>Consulenza Finanziaria<br/>e Gestione d'impresa</h1>
                  
                  <div className='absolute px-6 py-2 mt-4 flex items-center bg-slate-400 text-white rounded-tr-lg rounded-br-lg'>
                    <p className='px-2 '>Richiedi una consuleza</p>
                    <AiOutlinePhone/>
                  </div>

              </div>

              <div className='grid grid-cols-3 gap-4 py-4 md:mb-16 mb-8'>
                
                <div class="col-span-3 md:py-8 px-6 py-4 bg-white md:mb-16 mb-8">

                  <h1 className='text-slate-500'>Un paio di numeri sulla Finanza Agevolata</h1>
                  <div className='grid grid-cols-2 md:gap-6 gap-4'>

                    <div className='pt-4'>
                      <h1 className='font-bold lg:text-4xl md:text-3xl text-2xl'>350 miliardi</h1>
                      <p className='lg:text-2xl md:text-xl'>€ di incentivi pubblici stanziati per il periodo 2021-2027 a favore delle imprese italiane</p>
                    </div>

                    <div className='pt-4'>
                      <h1 className='font-bold lg:text-4xl md:text-3xl text-2xl'>57%</h1>
                      <p className='lg:text-2xl md:text-xl'>ammontare NON speso dall’Italia sul totale dei finanziamenti europei concessi al 2020</p>
                    </div>

                  </div>
                  <h1 className='pt-4 mt-4 lg:text-xl border-t border-slate-400 font-bold'>l’Italia è destinataria di un ammontare elevatissimo di agevolazioni pubbliche, ma gran parte di queste stesse agevolazioni, seppur messe a disposizione, non vengono usate dalle imprese.</h1>
                </div>
                
                <div class="bg-slate-400">
                  <img src={home1} className='object-cover' style={{ width: "100%", height: "100%" }}  alt="" />
                </div>
                
                <div class="col-span-2 px-4">
                <h1 className='lg:text-3xl md:text-2xl text-lg font-bold'>Finanza agevolata</h1>
                  <p className='lg:text-2xl md:text-xl'>
                  Non è facile per un’impresa districarsi nel mondo della finanza agevolata e nella burocrazia che assoggetta i numerosi incentivi che vengono messi a disposizione.<br/><br/> Serve avvalersi di professionisti specializzati nei rami economico-giuridici e ingegneristici per gestire l’intero processo di erogazione dell’incentivo.
                  </p>
                </div>
                
              </div>
              
            </div>

        </div>
  )
}

export default Home