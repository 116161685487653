import React, {useState} from 'react'
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import logo from '../imgs/logo.svg'

const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  return (
    <div className='w-full'>
      <div className="flex justify-between items-center h-20 mx-auto md:px-16 px-8 max-w-[1440px]">
        <div className='w-full md:text-2xl text-l' >
        <img  src={logo} width='240px'alt="StudioGuarnieri"/>
        </div>
        <ul className='hidden md:flex h-8'>
          <li className='p-1 px-4'>Home</li>
          <li className='p-1 px-4'>Servizi</li>
          <li className='p-1 px-4'>About</li>
          <li className='p-1 px-4'>Contatti</li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={24}/> : <AiOutlineMenu size={24} />}
        </div>
        <div className={nav ? 'absolute z-50 left-0 top-0 w-[70%] border-r border-t border-r-gray-200 h-full bg-white ease-in-out duration-500 text-black' : 'fixed left-[-100%]'}>
          <ul className='uppercase p-4'>
            <li className='p-4 border-b border-gray-100'>Home</li>
            <li className='p-4 border-b border-gray-100'>Servizi</li>
            <li className='p-4 border-b border-gray-100'>About</li>
            <li className='p-4'>Contatti</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar