import React from 'react';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Service from './components/Service';
import Footer from './components/Footer';

function App() {
  return (
    <div className='font-display bg-[#f4f6fb]'>
        <Navbar />
        <Home />
        <Service />
        <Footer />
    </div>
  );
}

export default App;
